<template>
    <!-- Calc Header -->
    <div class="col-12 bg-dark col-header">
      <h1 class="mt-3" v-html="apptitle"></h1>
      <h3 class="mb-3 d-none" v-html="appmode"></h3>
    </div>
</template>

<script>
export default {
  data () {
    return {
      apptitle: process.env.VUE_APP_TITLE,
      appmode: process.env.VUE_APP_MODE
    }
  }
}
</script>

<style scoped lang="scss">
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
  }

  .col-header {
    border-radius: 30px 30px 0 0;
  }
</style>
