<template>
  <div class="calculator">
    <div class="container-fluid container-form">

      <div class="row row-calc p-5">
        <!-- Calc Header -->
        <Header></Header>

        <!-- Calc Form -->
        <div class="col-12 bg-dark">
          <FinalForm :submit="submit">
            <form class="" slot-scope="props" @submit="props.handleSubmit">

              <!-- Calc Row Angebot -->
              <div id="calc" class="row row-angebot p-3 bg-info">

                <!-- Row Title -->
                <RowTitle txt='Angebot' color='light'> </RowTitle>

                <!-- Datum -->
                <div class="col-3">
                  <FinalField name="datum" :defaultValue="datum">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-datum">Datum</span>
                        </div>
                        <input id="datum" v-on="props.events" :name="props.name" class="form-control" :value="datum">
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>

                <!-- Kunde -->
                <div class="col-3">
                  <FinalField name="kunde" :validate="v => v ? null : '!'">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-kunde">Kunde</span>
                        </div>
                        <input v-on="props.events" :name="props.name" class="form-control">
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                          {{ props.meta.error }}
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>

                <!-- Angebot -->
                <div class="col-3">
                  <FinalField name="angebot" :validate="v => v ? null : '!'">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-angebot">Angebot</span>
                        </div>
                        <input v-on="props.events" :name="props.name" class="form-control">
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                          {{ props.meta.error }}
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>

                <!-- Rechnung -->
                <div class="col-3">
                  <FinalField name="rechnung" :validate="v => v ? null : '!'">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-datum">Rechnung</span>
                        </div>
                        <input v-on="props.events" :name="props.name" class="form-control">
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                          {{ props.meta.error }}
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>
              </div>

              <!-- Calc Row Format -->
              <div id="calc" class="row row-format p-3 bg-dark">
                <!-- Row Title -->
                <RowTitle txt='Format'  color='light'> </RowTitle>

                <!-- Höhe -->
                <div class="col-3">
                  <FinalField name="hoehe" :validate="v => v ? null : '!'">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-hoehe">Höhe</span>
                        </div>
                        <input v-on="props.events" :name="props.name" type="number" step=".1" class="form-control" value="">
                        <div class="input-group-append">
                          <span class="input-group-text" id="prep-hoehe">cm</span>
                        </div>
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                          {{ props.meta.error }}
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>

                <!-- Breite -->
                <div class="col-3">
                  <FinalField name="breite" :validate="v => v ? null : '!'">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-breite">Breite</span>
                        </div>
                        <input v-on="props.events" :name="props.name" class="form-control"  step=".1" type="number">
                        <div class="input-group-append">
                          <span class="input-group-text" id="prep-hoehe">cm</span>
                        </div>
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                          {{ props.meta.error }}
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>

                <!-- Umfang -->
                <div class="col-3">
                  <FinalField name="umfang">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-umfang">Umfang</span>
                        </div>
                        <input id="umfang" v-on="props.events" :name="props.name" class="form-control" disabled>
                        <div class="input-group-append">
                          <span class="input-group-text" id="prep-hoehe">cm</span>
                        </div>
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                          {{ props.meta.error }}
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>

                <!-- Fläche -->
                <div class="col-3">
                  <FinalField name="flaeche" :validate="v => v ? null : '!'">
                    <div slot-scope="props">
                      <div class="input-group my-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="prep-flaeche">Fläche</span>
                        </div>
                        <input id="flaeche" v-on="props.events" :name="props.name" class="form-control" disabled>
                        <div class="input-group-append">
                          <span class="input-group-text" id="prep-hoehe">cm²</span>
                        </div>
                        <span class="error pill" v-if="props.meta.error && props.meta.touched">
                          {{ props.meta.error }}
                        </span>
                      </div>
                    </div>
                  </FinalField>
                </div>
              </div>

              <!-- Calc Row Produkt -->
              <div id="calc" class="row row-produkt p-3 bg-light">
                <!-- Row Title -->
                <RowTitle txt='Produktoptionen'  color='dark'> </RowTitle>

                <!-- Produktoption 1 -->
                <div class="col-6 option-1">
                  <div class="row">
                    <div class="col-8">
                      <FinalField name="produkt1" :validate="v => v ? null : '!'">
                        <div slot-scope="props">
                          <div class="input-group my-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="prep-produkt1">Produkt 1</span>
                            </div>
                            <select id="produkt1" v-on="props.events" :name="props.name" class="form-control">
                              <option disabled selected value> -- Optionen -- </option>
                              <optgroup label="Holz">
                                <option value="25.90">Fichte</option>
                                <option value="17.90">Eiche</option>
                                <option value="47.90">Mahagoni</option>
                                <option value="47.90">Mahagoni - ein unglaublich langer Titel um extra lange Einträge zu testen</option>
                              </optgroup>
                              <optgroup label="PVC">
                                <option value="22.90">PVC Schwarz</option>
                                <option value="42.99">PVC Weiss</option>
                              </optgroup>
                            </select>
                            <span class="error pill" v-if="props.meta.error && props.meta.touched">
                              {{ props.meta.error }}
                            </span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                    <div class="col-4">
                      <FinalField name="produkt1_value">
                        <div class="input-group my-3">
                          <input disabled id="produkt1_value" value="" class="form-control">
                          <div class="input-group-append">
                              <span class="input-group-text" id="prep-produkt1">€</span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                  </div>
                </div>

                <!-- Produktoption 2 -->
                <div class="col-6 option-2">
                  <div class="row">
                    <div class="col-8">
                      <FinalField name="produkt2" :validate="v => v ? null : '!'">
                        <div slot-scope="props">
                          <div class="input-group my-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="prep-produkt2">Produkt 2</span>
                            </div>
                            <select id="produkt2" v-on="props.events" :name="props.name" class="form-control">
                              <option disabled selected value> -- Optionen -- </option>
                              <optgroup label="Swedish Cars">
                                <option value="5.90">Volvo</option>
                                <option value="7.90">Saab</option>
                              </optgroup>
                              <optgroup label="German Cars">
                                <option value="2.90">Mercedes</option>
                                <option value="4.99">Audi</option>
                              </optgroup>
                            </select>
                            <span class="error pill" v-if="props.meta.error && props.meta.touched">
                              {{ props.meta.error }}
                            </span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                    <div class="col-4">
                      <FinalField name="produkt2_value">
                        <div class="input-group my-3">
                          <input disabled id="produkt2_value" value="" class="form-control">
                          <div class="input-group-append">
                              <span class="input-group-text" id="prep-produkt2">€</span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                  </div>
                </div>

                <!-- Produktoption 3 -->
                <div class="col-6 option-3">
                  <FinalField name="produkt3" :validate="v => v ? null : '!'">
                    <div class="row">
                    <div class="col-8">
                      <FinalField name="produkt3" :validate="v => v ? null : '!'">
                        <div slot-scope="props">
                          <div class="input-group my-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="prep-produkt2">Produkt 3</span>
                            </div>
                            <select id="produkt3" v-on="props.events" :name="props.name" class="form-control">
                              <option disabled selected value> -- Optionen -- </option>
                              <optgroup label="Swedish Cars">
                                <option value="5.90">Volvo</option>
                                <option value="7.90">Saab</option>
                              </optgroup>
                              <optgroup label="German Cars">
                                <option value="2.90">Mercedes</option>
                                <option value="4.99">Audi</option>
                              </optgroup>
                            </select>
                            <span class="error pill" v-if="props.meta.error && props.meta.touched">
                              {{ props.meta.error }}
                            </span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                    <div class="col-4">
                      <FinalField name="produkt3_value">
                        <div class="input-group my-3">
                          <input disabled id="produkt3_value" value="" class="form-control">
                          <div class="input-group-append">
                              <span class="input-group-text" id="prep-produkt3">€</span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                  </div>
                  </FinalField>
                </div>

                <!-- Produktoption 4 -->
                <div class="col-6 option-4">
                  <FinalField name="produkt4" :validate="v => v ? null : '!'">
                    <div class="row">
                    <div class="col-8">
                      <FinalField name="produkt4" :validate="v => v ? null : '!'">
                        <div slot-scope="props">
                          <div class="input-group my-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="prep-produkt4">Produkt 4</span>
                            </div>
                            <select id="produkt4" v-on="props.events" :name="props.name" class="form-control">
                              <option disabled selected value> -- Optionen -- </option>
                              <optgroup label="Swedish Cars">
                                <option value="5.90">Volvo</option>
                                <option value="7.90">Saab</option>
                              </optgroup>
                              <optgroup label="German Cars">
                                <option value="2.90">Mercedes</option>
                                <option value="4.99">Audi</option>
                              </optgroup>
                            </select>
                            <span class="error pill" v-if="props.meta.error && props.meta.touched">
                              {{ props.meta.error }}
                            </span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                    <div class="col-4">
                      <FinalField name="produkt4_value">
                        <div class="input-group my-3">
                          <input disabled id="produkt4_value" value="" class="form-control">
                          <div class="input-group-append">
                              <span class="input-group-text" id="prep-produkt4">€</span>
                          </div>
                        </div>
                      </FinalField>
                    </div>
                  </div>
                  </FinalField>
                </div>

              </div>

              <div id="calc2" class="row row-produkt p-3 bg-light d-none">
                <!-- Row Title -->
                <RowTitle txt='Produktoptionen'  color='dark'> </RowTitle>

                <div v-for='option in optionen' :key="option">
                  <!-- Produktoption 1 -->
                  <div class="col-6 option-1" :class="option.value">
                    <div class="row">
                      <div class="col-8">
                        <FinalField :name="option" :validate="v => v ? null : '!'">
                          <div slot-scope="props">
                            <div class="input-group my-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" :id="option.value">Produkt {{ option.value }}</span>
                              </div>
                              <select id="produkt1" v-on="props.events" :name="props.name" class="form-control">
                                <option disabled selected value> -- Optionen -- </option>
                                <optgroup label="Holz">
                                  <option value="25.90">Fichte</option>
                                  <option value="17.90">Eiche</option>
                                  <option value="47.90">Mahagoni</option>
                                  <option value="47.90">Mahagoni - ein unglaublich langer Titel um extra lange Einträge zu testen</option>
                                </optgroup>
                                <optgroup label="PVC">
                                  <option value="22.90">PVC Schwarz</option>
                                  <option value="42.99">PVC Weiss</option>
                                </optgroup>
                              </select>
                              <span class="error pill" v-if="props.meta.error && props.meta.touched">
                                {{ props.meta.error }}
                              </span>
                            </div>
                          </div>
                        </FinalField>
                      </div>
                      <div class="col-4">
                        <FinalField :name="option.value + '_value'">
                          <div class="input-group my-3">
                            <input disabled id="produkt1_value" value="" class="form-control">
                            <div class="input-group-append">
                                <span class="input-group-text" id="prep-produkt1">€</span>
                            </div>
                          </div>
                        </FinalField>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Calc Row Output -->
              <div id="summe" class="row row-summe p-3 bg-success">
                <!-- Row Title -->
                <RowTitle txt='Summe'  color='light'> </RowTitle>
                <div class="col-6 col-summe">
                </div>
                <div class="col-2 col-summe">
                  <FinalField name="summe1_value">
                    <div class="input-group my-3">
                      <div class="input-group-append">
                          <span class="input-group-text" id="prep-produkt1">Rahmen</span>
                      </div>
                      <input disabled id="summe1_value" value="0" class="form-control">
                      <div class="input-group-append">
                          <span class="input-group-text" id="prep-produkt1">€</span>
                      </div>
                    </div>
                  </FinalField>
                </div>
                <div class="col-2 col-summe">
                  <FinalField name="summe2_value">
                    <div class="input-group my-3">
                      <div class="input-group-append">
                          <span class="input-group-text" id="prep-produkt1">Extras</span>
                      </div>
                      <input disabled id="summe2_value" value="0" class="form-control">
                      <div class="input-group-append">
                          <span class="input-group-text" id="prep-produkt1">€</span>
                      </div>
                    </div>
                  </FinalField>
                </div>
                <div class="col-2 col-summe">
                  <FinalField name="summe3_value">
                    <div class="input-group my-3">
                      <div class="input-group-append">
                          <span class="input-group-text" id="prep-produkt1">Gesamt</span>
                      </div>
                      <input disabled id="summe3_value" value="0" class="form-control">
                      <div class="input-group-append">
                          <span class="input-group-text" id="prep-produkt1">€</span>
                      </div>
                    </div>
                  </FinalField>
                </div>
              </div>

              <!-- Calc Row Output -->
              <div id="output" class="row row-output p-3">
                <!-- Row Title -->
                <RowTitle txt='Output'  color='light'> </RowTitle>
                <div class="col-12 col-output">
                  <input type="submit" value="Calculate" class="d-none form-control btn-success my-3">
                  <textarea class="form-control" disabled v-html="formText(props.values)" :initialValues="datum" rows="8"></textarea>
                  <div id="props" v-bind="props" class="mt-3 d-none">
                    {{ props }}
                    {{ props.values }}
                  </div>
                </div>
              </div>

            </form>
          </FinalForm>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import RowTitle from '@/components/RowTitle.vue'
import { FinalForm, FinalField } from 'vue-final-form'
import moment from 'moment'

export default {
  name: 'Calculator',
  components: {
    Header,
    RowTitle,
    FinalForm,
    FinalField
  },
  props: {
    output: Text,
    name: Text
  },
  methods: {
    submit (values) {
      console.log(JSON.stringify(values, undefined, 2))
    },

    formText (values) {
      let t = this.getDate()

      if (values.angebot) {
        t += '\n'
        t += 'Angebot: ' + values.angebot
      }
      if (values.rechnung) {
        t += '\n'
        t += 'Rechnung: ' + values.rechnung
      }
      if (values.kunde) {
        t += '\n'
        t += 'Kunde: ' + values.kunde
      }

      t += this.formCalc(values)
      t += this.formFormat(values)
      return t
    },

    formCalc (values) {
      let c = ''
      const g = document.getElementById('summe1_value')

      if (values.produkt1) {
        const e = document.getElementById('produkt1')
        const v = document.getElementById('produkt1_value')
        const value = e.options[e.selectedIndex].value
        const valueMwst = value * 1.19
        const text = e.options[e.selectedIndex].text
        c += '\nWert 1: ' + text + ' ' + value + ' € / ' + valueMwst + ' € inkl. MwSt'
        v.value = value
        g.value = value
      }
      if (values.produkt2) {
        const e = document.getElementById('produkt2')
        const v = document.getElementById('produkt2_value')
        const value = e.options[e.selectedIndex].value
        const valueMwst = value * 1.19
        const text = e.options[e.selectedIndex].text
        c += '\nWert 2: ' + text + ' ' + value + ' € / ' + valueMwst + ' € inkl. MwSt'
        v.value = value
        g.value = value
      }
      if (values.produkt3) {
        const e = document.getElementById('produkt3')
        const v = document.getElementById('produkt3_value')
        const value = e.options[e.selectedIndex].value
        const valueMwst = value * 1.19
        const text = e.options[e.selectedIndex].text
        c += '\nWert 3: ' + text + ' ' + value + ' € / ' + valueMwst + ' € inkl. MwSt'
        v.value = value
        g.value = value
      }
      if (values.produkt4) {
        const e = document.getElementById('produkt4')
        const v = document.getElementById('produkt4_value')
        const value = e.options[e.selectedIndex].value
        const valueMwst = value * 1.19
        const text = e.options[e.selectedIndex].text
        c += '\nWert 4: ' + text + ' ' + value + ' € / ' + valueMwst + ' € inkl. MwSt'
        v.value = value
        g.value = value
      }

      return c
    },

    formFormat (values) {
      const fl = document.getElementById('flaeche')
      const um = document.getElementById('umfang')
      // let f = ''
      // f += '\n--------- Format Calculation Here ----------\n'
      let flaeche = ''
      if (values.breite && values.hoehe) {
        fl.value = values.breite * values.hoehe
        parseFloat(fl.value).toFixed(2)
        um.value = (values.breite * 2) + (values.hoehe * 2)
        parseFloat(um.value).toFixed(2)
        flaeche = '\nFläche: ' + parseFloat(values.breite * values.hoehe).toFixed(1) + 'cm² / ' + parseFloat(values.breite * values.hoehe / 10000) + 'm²'
      }
      return flaeche
    },

    getDate () {
      // const now = new Date()
      return moment().locale('de').format('DD.MM.YYYY')
    },

    setDate () {
      const today = Date()
      const d = document.getElementById('datum')
      d.value = today
    }
  },

  data () {
    return {
      apptitle: process.env.VUE_APP_TITLE,
      appmode: process.env.VUE_APP_MODE,
      datum: this.getDate(),
      optionen: ['option1', 'option2', 'option3', 'option4']
    }
  },

  mounted () {
    this.setDate()
    console.log('Component has been mounted!')
  },

  created () {
    console.log('Component has been created!')
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
  }

  #props {
    color: #fff;
  }

  .error {
    background-color: white;
    color: red;
    padding: 5px;
    font-size: 0.85rem;
    font-weight: bold;
    line-height: 1.5rem;
  }

  .container-form {
    background-color: #ccc;
    padding: 15px;
  }

  .col-title h3{
    text-align: left;
    font-size: 0.85rem;
    text-transform: uppercase;
  }

  .bg-dark h3 {
    color: #fff;
  }
  .bg-light h3 {
    color: #000;
  }
  option {
    background-color: #fff;
  }

</style>
