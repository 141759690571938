<template>
  <div class="col-12 col-title" :class="color">
    <h3>{{txt}}</h3>
  </div>
</template>

<script>
export default {
  props: {
    txt: String,
    color: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .col-title h3{
    text-align: left;
    font-size: 0.85rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .col-title h3 {
    color: #fff;
  }

  .dark h3{
    color: #000;
  }

  .light h3{
    color: #fff;
  }

  </style>
